import React, { Component } from 'react';
import { withTranslation } from 'server/i18n';
import { Link } from 'server/next-routes';

import cx from 'classnames';

import { withRouter } from 'next/router';
import { inject, observer } from 'mobx-react';

import IconPlume from 'components/icons/Plume';
import css from './style.css';
import IconSunrise from '../icons/Sunrise';


const MINI_NAV_NORMAL = [
  {
    id: 'membershipBenefits',
    label: 'common:Membership Benefits',
    route: 'https://www.plume.com/homepass/#membership-benefits',
  },
  {
    id: 'yourPrivacy',
    label: 'common:Your Privacy',
    route: 'https://www.plume.com/legal/privacy',
  },
  {
    id: 'support',
    label: 'common:support.label',
    route: 'https://support.plume.com/',
  },
  {
    id: 'buy-pods',
    label: 'Buy more pods',
    route: 'https://shop.plume.com/homepass/add-pods',
  },
];

const MINI_NAV_BE = [
  {
    id: 'membershipBenefits',
    label: 'Les avantages',
    route: 'https://www.plume.com/fr-BE/homepass/#membership-benefits',
  },
  {
    id: 'yourPrivacy',
    label: 'Confidentialité',
    route: 'https://www.plume.com/fr-BE/legal/privacy',
  },
  {
    id: 'support',
    label: 'common:support.label',
    route: 'https://support.plume.com/hc/fr',
  },
  {
    id: 'buy-pods',
    label: 'Acheter pods',
    route: 'https://shop.plume.com/homepass/add-pods',
  },
];

const MINI_NAV_DE = [
  {
    id: 'membershipBenefits',
    label: 'Abo Vorteile',
    route: 'https://www.plume.com/de-DE/homepass/#membership-benefits',
  },
  {
    id: 'yourPrivacy',
    label: 'Datenschutz',
    route: 'https://www.plume.com/de-DE/legal/privacy/',
  },
  {
    id: 'support',
    label: 'common:support.label',
    route: 'https://support.plume.com/hc/de',
  },
  {
    id: 'buy-pods',
    label: 'Kaufen pods',
    route: 'https://shop.plume.com/de-DE/homepass/add-pods',
  },
];

const MINI_NAV_UK = [
  {
    id: 'membershipBenefits',
    label: 'common:Membership Benefits',
    route: 'https://www.plume.com/en-GB/homepass/#membership-benefits',
  },
  {
    id: 'yourPrivacy',
    label: 'common:Your Privacy',
    route: 'https://www.plume.com/en-GB/legal/privacy/',
  },
  {
    id: 'support',
    label: 'common:support.label',
    route: 'https://support.plume.com/s/?language=en_US',
  },
];

const MINI_NAV_CY = [
  {
    id: 'membershipBenefits',
    label: 'common:Membership Benefits',
    route: 'https://www.plume.com/en-GB/homepass/',
  },
  {
    id: 'yourPrivacy',
    label: 'common:Your Privacy',
    route: 'https://www.plume.com/en-GB/legal/privacy/',
  },
  {
    id: 'support',
    label: 'common:support.label',
    route: 'https://support.plume.com/hc/en-gb',
  },
];

const MINI_NAV_VM = [
  {
    id: 'membershipBenefits',
    label: 'common:Membership Benefits',
    route: 'https://www.plume.com/en-GB/homepass/#membership-benefits',
  },
  {
    id: 'yourPrivacy',
    label: 'common:Your Privacy',
    route: 'https://www.plume.com/en-GB/legal/privacy/',
  },
  {
    id: 'support',
    label: 'common:support.label',
    route: 'https://support.plume.com/s/?language=en_US',
  },
];

@withRouter
@withTranslation('home')
@inject('mobxStore')
@observer
class HeaderMini extends Component {
  reqFrame;

  constructor(props) {
    super(props);
    this.state = {
      isCartHover: false,
    };
  }


  componentDidMount() {
    document.getElementsByTagName('html')[0].classList.add('sticky-active');
  }

  handleMouseOver = () => {
    this.setState({
      isCartHover: true,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      isCartHover: false,
    });
  };

  handleMouseClick = () => {
    setTimeout(() => {
      this.setState({
        isCartHover: false,
      });
    }, 300);
  };

  handleLogoClick = () => {
    this.scrollToTop();
    const el = document.getElementsByClassName('menu-wrapper')[0];
    if (window.matchMedia('(max-width: 993px)').matches) {
      el.classList.remove('mobile-nav-active');
    }
  }

  scrollToTop = () => {
    const scrollStep = -window.scrollY / (250 / 15);
    const scrollInterval = setInterval(() => {
      if (window.scrollY !== 0) {
        window.scrollBy(0, scrollStep);
      } else clearInterval(scrollInterval);
    }, 15);
  }

  toggleMobileNav = () => {
    const htmlTag = document.getElementsByClassName('menu-wrapper')[0];
    if (window.matchMedia('(max-width: 993px)').matches) {
      htmlTag.classList.toggle('mobile-nav-active');
    }
  }

  openLoginModal = () => {
    const {
      mobxStore: {
        uiStore: {
          setActiveModal,
        },
        magicLinkStore: {
          resetStatus,
          sending,
        },
      },
    } = this.props;

    if (!sending) resetStatus();
    setActiveModal('login-auth');
  }

  renderLogo = () => {
    const {
      mobxStore: {
        country,
        authStore: {
          isMobile,
          isAuthenticated,
        },
      },
      router,
    } = this.props;

    if (router.pathname === '/expired-token') {
      if (
        isAuthenticated
        // router.query.country === 'ch' &&
        && country === 'ch'
      ) {
        return (
          <a
            href="/ch/sunrise/store"
            id="navigation__linkHome"
            onClick={() => {
              this.handleLogoClick();
            }}
          >
            <IconPlume />
          </a>
        );
      }
      if (
        isAuthenticated
        && country !== 'ch'
      ) {
        return (
          <Link route="/">
            <a
              id="navigation__linkHome"
              onClick={() => {
                this.handleLogoClick();
              }}
            >
              <IconPlume />
            </a>
          </Link>
        );
      }
      if (country === 'ch' || router.query.country === 'ch') {
        return (
          <a
            href="/ch/sunrise/store"
            id="navigation__linkHome"
            onClick={() => {
              this.handleLogoClick();
            }}
          >
            <IconPlume />
          </a>
        );
      }
      return (
        <div>
          <img src="/static/images/plume-logo-dark.svg" alt="" />
        </div>
      );
    }

    if (router.pathname !== '/') {
      if (country === 'ch') {
        return (
          <Link route="/">
            <a
              id="navigation__linkHome"
              onClick={this.handleLogoClick}
            >
              <IconSunrise />
              {' '}
              <IconPlume />
            </a>
          </Link>
        );
      }
      if (isMobile) {
        return (
          <Link route="/">
            <a
              id="navigation__linkHome"
              onClick={this.handleLogoClick}
            >
              <IconPlume />
            </a>
          </Link>
        );
      }
      return (
        <div>
          <img src="/static/images/plume-logo-dark.svg" alt="" />
        </div>
      );
    }
    return (
      <div>
        <img src="/static/images/plume-logo-dark.svg" alt="" />
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const {
      router,
      mobxStore: {
        country,
        authStore: {
          isMobile,
          isAuthenticated,
          logout,
        },
        uiStore: {
          isHeaderSticky,
          setActiveModal,
        },
      },
    } = this.props;
    // const isLegal = router.pathname.includes('/legal');


    let MINI_NAV = country === 'ch' ? [] : (country === 'de' ? MINI_NAV_DE : (country === 'be' ? MINI_NAV_BE : MINI_NAV_NORMAL));

    if (country === 'uk') {
      MINI_NAV = MINI_NAV_UK;
    }

    if (country === 'cy') {
      MINI_NAV = MINI_NAV_CY;
    }

    if (router.pathname.includes('/virginmedia')) {
      MINI_NAV = MINI_NAV_VM;
    }

    return (
      <div
        id="headerMini"
        ref={(el) => {
          this.el = el;
        }}
        className={cx(css['header-mini'], { [css.sticky]: isHeaderSticky })}
      >

        <div className={cx('menu-wrapper', 'menu-indicator', css.content)}>
          <div className={css.brand}>
            {this.renderLogo()}
          </div>
          <div className={cx(css.navigation, css['set-sticky'], 'mobile-menu')}>
            {
              MINI_NAV.map(({ id, label, route }) => (
                <a
                  key={id}
                  target="_blank"
                  rel="noopener noreferrer"
                  href={route}
                  id={`header-${id}-link`}
                  className={cx('menu-item', css.column, { active: true })}
                  onClick={this.toggleMobileNav}
                >
                  <p className={cx('regular', 'nav-links', css['header-mini-text'])}>{t(label)}</p>
                </a>
              ))
            }
          </div>
          <div className={css['header-trigger-container']}>
            <div className={css.cartButtonContainer}>
              <a
                className={cx(css.cart, {
                  [css.cartHover]: this.state.isCartHover,
                })}
                href={`https://shop.plume.com${country === 'de' ? '/de' : ''}${country === 'be' ? '/be' : ''}${country === 'uk' ? '/gb' : ''}${country === 'cy' ? '/cy' : ''}/homepass/select-hardware`}
                onMouseLeave={this.handleMouseLeave}
                onTouchEnd={this.handleMouseClick}
                onTouchStart={this.handleMouseOver}
                onMouseOver={this.handleMouseOver}
                onFocus={() => {}}
                onBlur={() => {}}
              >
                Shop
              </a>
            </div>
            <a
              className={cx(css['header-trigger'])}
              id="header__nav_trigger"
              onClick={this.toggleMobileNav}
            >
              <span><img src="/static/images/icon-x.svg" alt="Close" /></span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderMini;
